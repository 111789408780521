import * as React from 'react';
import InputHandler from 'Components/InputHandler';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import ActionButton from 'Components/ActionButton';
import {
  dialogContent,
  dialogActions,
  dialogCancel,
  dialogTitle,
} from '../../../../../../../config/theme/componentTheme';
import FormRow from 'Components/FormRow';
import { nowPartialDate, partialDateToValue } from 'neuro-utils';
import colors from '../../../../../../../config/theme/colors';
import { actions } from 'Store/myapp/actions';
import { useDispatch } from 'react-redux';
import { omit } from 'Utility/ramdaReplacement';
import { useAppSelector } from 'Store/index';

const TaskListDialog = ({ taskActionType, sessionDate, dialogOpen, setDialogOpen, fm }: IOwnProps): JSX.Element => {
  const dispatch = useDispatch();

  const platform = useAppSelector((s: IState) => s.session.platforms?.selected);
  const locale = useAppSelector((s: IState) => s.settings?.userSettings?.uiLanguage) ?? 'fi';
  const taskUiSettings = useAppSelector((s: IState) => s.settings?.orgSettings?.settings?.taskUiSettings);
  const platformSettings = platform ? taskUiSettings?.[platform] : undefined;

  const initData: ITaskListData = {
    title: taskActionType,
    deadline: sessionDate ?? nowPartialDate(),
    deadlineTime: [23, 59],
    sendDate: nowPartialDate(),
    tasks: [
      taskActionType === 'tdcsTreatmentEfficiencyBefore' ? 'treatmentEfficiencyBefore' : 'treatmentEfficiencyAfter',
    ],
    description: platformSettings?.[taskActionType]?.descriptionLocalized?.[locale],
  };

  // Temporary data when dialog is open
  const [data, setData] = React.useState<ITaskListData>(initData);

  const handleChange = (e: TOnChangeValues) => {
    const name = Object.keys(e)[0];
    const value = e[name];
    setData((prev: typeof data) => ({ ...prev, [name]: value }));
  };

  const handleSend = () => {
    const tasklistData: ITaskList = omit(['deadline', 'deadlineTime'], data) as ITaskList;

    // Get the base timestamp for the deadline date (interpreted as local midnight)
    let end_time = partialDateToValue(data.deadline);

    if (data.deadlineTime) {
      const hours = data.deadlineTime[0] || 0;
      const minutes = data.deadlineTime[1] || 0;

      const localDate = new Date(end_time);

      // Convert local time to UTC
      const utcHours = hours + localDate.getTimezoneOffset() / 60;

      // Create a UTC date object
      const utcDate = new Date(
        Date.UTC(localDate.getFullYear(), localDate.getMonth(), localDate.getDate(), utcHours, minutes),
      );

      end_time = utcDate.getTime();
    }

    tasklistData.end_time = end_time;

    actions.newTasklist(tasklistData, dispatch).then(() => {
      // TODO: Add info if send fails
      setDialogOpen(false);
    });
  };

  // Reset data when dialog is opened (again)
  React.useEffect(() => {
    setData(initData);
  }, [dialogOpen]);

  return (
    <Dialog
      open={dialogOpen}
      maxWidth="sm"
      fullWidth={true}
      PaperProps={{ square: true }}
      onClose={() => setDialogOpen(false)}
    >
      <DialogTitle style={dialogTitle}>
        {fm('myService.taskList')}
        <div style={{ fontSize: '1.6rem', fontWeight: 400, color: colors.black, padding: '0.5rem 0 1.5rem 0' }}>
          {fm('myService.taskListInfoTextAlt')}
        </div>
      </DialogTitle>
      <DialogContent style={dialogContent}>
        <FormRow title="myService.taskListTitle">
          <InputHandler
            name="title"
            type="Select"
            editing={false}
            options={['tdcsTreatmentEfficiencyBefore', 'tdcsTreatmentEfficiencyAfter']}
            optionFormatter={(o) =>
              platformSettings && Object.keys(platformSettings).length > 0
                ? `${platformSettings?.[o]?.titleLocalized[locale] ?? platformSettings?.[o]?.titleLocalized['fi']}`
                : fm(`myService.opts.${o}`)
            }
            formData={{
              document: { title: data.title },
              onChange: handleChange,
            }}
            placeholder="myService.taskListTitlePlaceholder"
            width={50}
            // fittedWidth
          />
        </FormRow>
        <FormRow title="myService.deadline">
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <InputHandler
              name="deadline"
              type="PartialDate"
              editing={false}
              formData={{ document: { deadline: data.deadline }, onChange: handleChange }}
              dateHook={{ dateHookFloor: nowPartialDate() }}
              isNotCancellable
            />
          </div>
        </FormRow>
        <FormRow title="myService.deadlineTime">
          <InputHandler
            name="deadlineTime"
            type="TimePicker"
            editing={true}
            formData={{ document: { deadlineTime: data.deadlineTime }, onChange: handleChange }}
            timeDefault={[23, 59]}
            isNotCancellable
            minTime={new Date()}
          />
        </FormRow>
        <FormRow title="myService.tasksForTaskList">
          <InputHandler
            name="tasks"
            type="Checkbox"
            editing={false}
            options={['treatmentEfficiencyBefore', 'treatmentEfficiencyAfter']}
            optionFormatter={(name: string | number) =>
              name
                ? `${fm('myService.ninmt.treatmentEfficiency')} ${fm(
                    `tdcs.patientsRating.${(name as string).indexOf('Before') > -1 ? 'before' : 'after'}Treatment`,
                  ).toLocaleLowerCase()}`
                : '-'
            }
            formData={{ document: { tasks: data.tasks }, onChange: handleChange }}
          />
        </FormRow>
        <FormRow title="myService.taskListInfo">
          <InputHandler
            name="description"
            type="TextArea"
            editing={true}
            formData={{ document: { description: data.description }, onChange: handleChange }}
            placeholder={fm('myService.taskListInfoPlaceholder')}
            disablePlaceholderFormatting
            rows={14}
            maxHeight={25}
          />
        </FormRow>
      </DialogContent>
      <DialogActions style={{ ...dialogActions, justifyContent: 'flex-end' }}>
        <div>
          <div style={{ ...dialogCancel, display: 'inline-block' }} onClick={() => setDialogOpen(false)}>
            {fm('general.cancel')}
          </div>
          <div style={{ display: 'inline-block' }}>
            <ActionButton text="myService.send" onClick={handleSend} width={12} height={3} fontSize={16} />
          </div>
        </div>
      </DialogActions>
    </Dialog>
  );
};

/** The data used to compile a tasklist */
interface ITaskListData extends Pick<ITaskList, 'title' | 'deadline' | 'sendDate' | 'tasks' | 'description'> {
  deadlineTime: Time | undefined;
}

interface IOwnProps {
  taskActionType: 'tdcsTreatmentEfficiencyBefore' | 'tdcsTreatmentEfficiencyAfter';
  sessionDate: PartialDate;
  dialogOpen: boolean;
  setDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
  fm: (m: string) => string;
}

export default TaskListDialog;
