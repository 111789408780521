import * as React from 'react';
import TabContent from 'Components/TabContent';
import { convertSymptomsAndLocationsToRatingFormat, convertTreatmentEfficiencyToRatingFormat } from 'Utility/ninmtUtil';
import PatientsRating from '../PatientsRating';
import { equals } from 'ramda';
import { styled } from '@mui/system';
import colors from '../../../../../../../config/theme/colors';
import { TDCSContext } from 'Routes/Tdcs/Document';
import { nowPartialDate, partialDateToValue } from 'neuro-utils';

const EmptyTabContent = styled('div')({
  margin: '0rem 0rem 0rem 0rem',
  padding: '2rem 2rem 2rem 2rem',
  marginRight: '0rem',
  border: `1px solid ${colors.gray}`,
  backgroundColor: colors.white,
});

const PatientsRatings = ({
  editIndex,
  editingEvent = true,
  formData,
  fm,
  symptomsAndLocations,
  sessionDate,
}: {
  editIndex: number;
  editingEvent?: boolean;
  formData: IFormData<ITDCS>;
  fm: (m: string) => string;
  symptomsAndLocations: Array<ISymptomsAndLocations>;
  sessionDate?: PartialDate;
}): JSX.Element => {
  const tdcsContext = React.useContext(TDCSContext);
  const { sortedAndMergedMyDocuments, patientMySQStatus } = tdcsContext;

  const treatmentEfficiencyDocs = sortedAndMergedMyDocuments.filter(
    (d) => d._type === 'treatmentEfficiency' && partialDateToValue(d.date) === partialDateToValue(sessionDate),
  ) as ITreatmentEfficiency[];

  const before = (treatmentEfficiencyDocs ?? []).find((d) => d.timing === 'before');
  const after = (treatmentEfficiencyDocs ?? []).find((d) => d.timing === 'after');

  const { document, onChange } = formData;

  // For treatment efficiency values
  const [treatmentEfficiencyRating, setTreatmentEfficiencyRating] = React.useState<Array<ITDCSRating> | undefined>(
    undefined,
  );

  // Initialize/update patient's rating array or treatment efficiency
  React.useEffect(() => {
    // If invites have been sent, patient is using My service --> don't initialize/update patient's rating array
    if (
      patientMySQStatus &&
      (partialDateToValue(nowPartialDate()) === partialDateToValue(sessionDate) || before || after)
    ) {
      if (before) {
        setTreatmentEfficiencyRating(
          convertTreatmentEfficiencyToRatingFormat(before && after ? [before, after] : before),
        );
      } else setTreatmentEfficiencyRating([]);
      return;
    } else {
      setTreatmentEfficiencyRating(undefined);
    }

    if (!document.sessions?.[editIndex].patientsRating && editingEvent) {
      const newSessions: Array<ITDCSSession> = JSON.parse(JSON.stringify(document.sessions));
      if (newSessions?.[editIndex].date) {
        const newPatientsRating = convertSymptomsAndLocationsToRatingFormat(
          newSessions?.[editIndex],
          symptomsAndLocations,
        );
        const currentPatientsRating = newSessions?.[editIndex].patientsRating;

        if ((!currentPatientsRating || currentPatientsRating.length === 0) && newPatientsRating.length > 0) {
          newSessions[editIndex].patientsRating = newPatientsRating;
          onChange && onChange({ sessions: newSessions });
          return;
        }

        if (
          !equals(
            newPatientsRating.map((n) => n.ratingByLocation?.map((r) => r.symptomsAndLocationsId)),
            currentPatientsRating?.map((c) => c.ratingByLocation?.map((r) => r.symptomsAndLocationsId)),
          )
        ) {
          newSessions[editIndex].patientsRating = newPatientsRating;
          onChange && onChange({ sessions: newSessions });
          return;
        }
      }
    }
  }, [sessionDate, symptomsAndLocations]);

  const patientsRating = treatmentEfficiencyRating
    ? treatmentEfficiencyRating
    : Array.isArray(document.sessions?.[editIndex].patientsRating) &&
        (document.sessions?.[editIndex]?.patientsRating ?? []).filter((s) => s).length > 0
      ? document.sessions?.[editIndex].patientsRating
      : [];

  // onChange for individual patient's ratings
  const patientsRatingOnChange =
    (index: number) =>
    (value: TOnChangeValues): void => {
      // Check if there exists an patient's rating with given index
      if (Array.isArray(patientsRating) && patientsRating.length >= index) {
        const newSessions = JSON.parse(JSON.stringify(document.sessions));
        const newPatientsRating = JSON.parse(JSON.stringify(patientsRating));
        newPatientsRating[index] = Object.assign({}, newPatientsRating[index], value);
        newSessions[editIndex].patientsRating = newPatientsRating;
        onChange && onChange({ sessions: newSessions });
      }
    };

  return Array.isArray(patientsRating) && patientsRating.length > 0 ? (
    <TabContent>
      {
        patientsRating?.map((s: ITDCSRating, i: number) => ({
          key: `${s.id}${i}`,
          id: `${s.id}Tab${i}`,
          title: s.symptom ? fm(`tdcs.patientsRating.opts.${s.symptom}`) : fm('tdcs.patientsRating.symptomPlaceholder'),
          content: (
            <PatientsRating
              document={s}
              onChange={patientsRatingOnChange(i)}
              editingEvent={treatmentEfficiencyRating ? false : editingEvent}
            />
          ),
          count: i,
        })) as []
      }
    </TabContent>
  ) : (
    <EmptyTabContent>
      {fm(
        `tdcs.patientsRating.${
          symptomsAndLocations?.length > 0 && treatmentEfficiencyRating
            ? 'noTreatmentEfficiency'
            : 'noSymptomsAndLocations'
        }`,
      )}
    </EmptyTabContent>
  );
};

export default PatientsRatings;
