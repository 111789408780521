import * as React from 'react';
import { Stepper, Step, useMediaQuery, Theme } from '@mui/material/';
import { FormattedMessage } from 'react-intl';

import FormRow from '../FormRow';
import DeleteDialog from '../ConfirmationDialog';

import FullDeleteDialog, { IDeleteDetails } from 'Components/HistoryRowControls/DeleteDialog';

import ToolTip from '../ToolTip';
import ActionButton from '../ActionButton';
import { Container, Item } from '../Grid';

import { exists, sortPartialDate, sortTime } from 'neuro-utils';

import EditingEvent from './EditingEvent';
import {
  Connector,
  HeaderControls,
  IconChooser,
  StandAloneContent,
  StandAloneHeader,
  StyledLabelText,
  StyledStepContent,
  StyledStepLabel,
  StyledViewSelector,
  StyledTableControls,
} from './components';
import { createEventItem, deleteEvent, onChangeUnit, sortByDateAndSave, TDeleteProps } from './utils';
import colors from '../../config/theme/colors';
import { equals, isEmpty } from 'ramda';
import ConditionalCollapse from 'Components/Collapse/ConditionalCollapse';
import { controlProps } from 'Utility/documentHandling';
import { getJWTData } from 'Utility/jwtAuthTools';
import { omit } from 'Utility/ramdaReplacement';

const dateNotValid = (date: PartialDate): boolean => !date || (!date?.[0] && !date?.[1] && !date?.[2]);

const _EventStepper = (props: TEventStepperProps): JSX.Element => {
  const { type } = props;

  const isGroup = type === 'group';

  const types = isGroup ? Object.keys(props.propsByType) : [];
  const combinedEvents = isGroup ? props.combinedEvents : [];

  const {
    name,
    formData,
    viewing = false,
    stepLabelText,
    stepContent,
    separateLatest,
    allClosed,
    addNewTextHeader,
    addNewTextButton,
    buttonDisabled,
    buttonDisabledMessage,
    buttonDisabledElement,
    saveDisabled,
    saveDisabledMessage,
    latestEventTextHeader,
    previousEventsTextHeader,
    noPreviousEventsTextHeader,
    previousEventsCollapseLimit,
    previousEventsCollapseMessage,
    editingElements,
    editingExtraElement,
    noConcurrence = false,
    noConcurrenceText,
    editingModeOn,
    customCreateEventItem,
    mutators,
    dateFieldName,
    deleteDialogMode = 'default',
    deleteMessage,
    defaultValues,
    deleteAction,
    tableContent,
    tableContentFilter,
    theme,
    hideNewButton,
    saveExtraAction,
    cancelExtraAction,
    addNewExtraAction,
    startEditExtraAction,
    defaultView,
    readOnlyEvents = [],
  } = isGroup ? props.propsByType[types[0]] : props;

  // Track page width and breakpoints
  const md = useMediaQuery((theme: Theme) => theme.breakpoints.between(0, 1401));

  // Get suitable event limit displayed in table view based on page width
  const getEventLimit = () => {
    if (md) return 2;
    return 3;
  };

  // Where the menu will spawn
  const [anchor, setAnchor] = React.useState<null | { anchor: HTMLElement; index: number }>(null);
  // Used to determine whether list or table view is active
  const [activeView, setActiveView] = React.useState<TTableView>(defaultView ? defaultView : 'list');
  // Used to change the range of events displayed in table view
  const [eventRange, setEventRange] = React.useState<[number, number]>([0, getEventLimit()]);
  // Render control
  const [thisEventsLength, setThisEventsLength] = React.useState<number | undefined>(undefined);

  const changeEventRange = (change: number) => (): void => {
    let newRange: React.SetStateAction<[number, number]> = [...eventRange];
    newRange = [newRange[0] + change, newRange[1] + change];
    setEventRange(newRange);
  };

  const toggleMenu =
    (index: number) =>
    (e: React.MouseEvent<any>): void => {
      setAnchor(!anchor ? { anchor: e.currentTarget, index } : null);
      allClosed && e.stopPropagation();
    };

  // Use this variable to know whether new event is being added and render events based on it (true means new event, otherwise number is event index)
  const [editingEvent, setEditingEvent] = React.useState<boolean | number>(editingModeOn ? true : false);

  // Track what type of event is being edited
  const [editingEventType, setEditingEventType] = React.useState<string | undefined>(undefined);

  // Active / open step for stepper
  const [activeStep, setActiveStep] = React.useState<number>(separateLatest || allClosed ? -1 : 0);

  const activateStep = (i: number, enableAllClosed?: true) => (): void => {
    if ((separateLatest ? i - 1 : i) !== activeStep) setActiveStep(separateLatest ? i - 1 : i);
    else if (allClosed && enableAllClosed) {
      setActiveStep(-1);
    }
  };

  // Close all previous events if new ones are added/deleted
  const [numberOfDocs, setNumberOfDocs] = React.useState<number>(0);

  React.useEffect(() => {
    setNumberOfDocs(isGroup ? combinedEvents.length : formData.document?.[name]?.length);
  }, [formData.document, name]);

  React.useEffect(() => {
    activateStep(separateLatest || allClosed ? -1 : 0)();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [numberOfDocs]);

  const setEditing =
    (i: number, t?: string) =>
    (e: React.MouseEvent): void => {
      startEditExtraAction?.();
      e.stopPropagation();
      setEditingEvent(i);
      activateStep(i)();
      if (isGroup && t) setEditingEventType(t);
    };

  const addingNewEvent = editingEvent === true;
  const editing = !!(editingEvent || editingEvent === 0);

  // Combined events of different types sorted for reference order
  const combinedEventsSorted = combinedEvents
    // Sorting mimics sortByDateAndSave function
    .toSorted((s1, s2) => (s2.createDate ?? 0) - (s1.createDate ?? 0))
    .sort((n1, n2) => {
      // Some events might use startDate and startTime instead of date and time
      if (isGroup && (props.propsByType[n1._type].dateFieldName || props.propsByType[n2._type].dateFieldName)) {
        const dateFieldName1 = props.propsByType[n1._type].dateFieldName ?? (n1.startDate ? 'startDate' : 'date');
        const dateFieldName2 = props.propsByType[n2._type].dateFieldName ?? (n2.startDate ? 'startDate' : 'date');
        if ((dateFieldName1 === 'startDate' || dateFieldName2 === 'startDate') && (n1.startTime || n2.startTime)) {
          return sortPartialDate(n2[dateFieldName2], n1(dateFieldName1)) || sortTime(n2.startTime, n1.startTime);
        }
        return sortPartialDate(n2[dateFieldName2], n1(dateFieldName1)) || sortTime(n2.startTime, n1.startTime);
      }
      if (n1.startDate || n2.startDate) {
        const date1 = n1.startDate ?? n1.date;
        const date2 = n2.startDate ?? n2.date;
        if (n1.startTime || n2.startTime) return sortPartialDate(date2, date1) || sortTime(n2.startTime, n1.startTime);
        return sortPartialDate(date2, date1);
      }

      if (n1.time || n2.time) return sortPartialDate(n2.date, n1.date) || sortTime(n2.time, n1.time);
      return sortPartialDate(n2.date, n1.date);
    });

  // Reference order for sorting combined events, update only after saving, canceling etc.
  const combinedEventsSortedRef = React.useRef<any[]>(combinedEventsSorted);
  const combinedEventsSortedRefIds = combinedEventsSortedRef.current.map((e) => e.id);

  const _thisEvents = isGroup
    ? // Sort combined events here based on reference order
      combinedEvents.toSorted(
        (a, b) => combinedEventsSortedRefIds.indexOf(a.id) - combinedEventsSortedRefIds.indexOf(b.id),
      )
    : Array.isArray(formData.document?.[name])
      ? formData.document?.[name]
      : [];

  // Temporary editing data to keep events in initial order while editing, this allows sorting actual form data in the background
  const editingEvents = React.useRef<typeof _thisEvents | undefined>(undefined);
  // Helper index used to locate current event if events are sorted while editing
  const [editingIndex, setEditingIndex] = React.useState<number | undefined>(undefined);

  // Set and unset temporary editing data
  React.useEffect(() => {
    if (editing && !editingEvents.current) {
      editingEvents.current = structuredClone(_thisEvents);
    } else {
      editingEvents.current = undefined;
    }
  }, [editing]);

  // Pick current data based on editing status
  const thisEvents = structuredClone(editingEvents.current ? editingEvents.current : _thisEvents);

  const cancelAddNew =
    (i = 0, n?: string) =>
    (): void => {
      cancelExtraAction?.();
      deleteEvent(i, n ? n : name, isGroup && n ? props.propsByType[n].formData : formData)();
      setEditingEvent(false);
      setEditingEventType(undefined);
      if (isGroup) combinedEventsSortedRef.current = combinedEventsSorted;
    };

  const save = (n?: string) => {
    // Briefly equalize temporary editing data with actual form data - temporary data is set to undefined when editing ends
    editingEvents.current = _thisEvents;
    saveExtraAction?.();
    sortByDateAndSave(
      n ? n : name,
      isGroup && n ? props.propsByType[n].formData : formData,
      isGroup && n ? props.propsByType[n].dateFieldName : dateFieldName,
    );
    setEditingEvent(false);
    setEditingEventType(undefined);
    if (isGroup) combinedEventsSortedRef.current = combinedEventsSorted;
  };

  // Set index for deletion
  const [deleteIndex, setDeleteIndex] = React.useState<number | null>(null);

  const openDeleteDialog =
    (i: number, t?: string) =>
    (e: React.MouseEvent): void => {
      e.stopPropagation();
      if (isGroup && t) setEditingEventType(t);
      setDeleteIndex(i);
      setAnchor(null);
    };

  const deleteCancelCallback = (): void => {
    setDeleteIndex(null);
    setEditingEventType(undefined);
  };

  const deleteConfirmCallback = (deleteProps?: TDeleteProps): void => {
    if (deleteIndex || deleteIndex === 0) {
      if (isGroup && editingEventType) {
        const i = props.propsByType[editingEventType].formData.document[editingEventType].findIndex(
          (e: any) => thisEvents[deleteIndex].id === e.id,
        );
        props.propsByType[editingEventType].deleteAction?.(i, props.propsByType[editingEventType].formData)();
        deleteEvent(i, editingEventType, props.propsByType[editingEventType].formData, deleteProps)();
      } else {
        deleteAction?.(deleteIndex, formData)();
        deleteEvent(deleteIndex, name, formData, deleteProps)();
      }
      setDeleteIndex(null);
      setEditingEventType(undefined);
      if (isGroup) combinedEventsSortedRef.current = combinedEventsSorted;
    }
  };

  // Hack to prevent render until length of event array has been updated
  React.useEffect(() => {
    // Hack to prevent previous event opening if a new event is canceled outside EventStepper
    if (thisEvents.length < (thisEventsLength ?? 0)) {
      setEditingEvent(false);
    }
    setThisEventsLength(thisEvents.length);
  }, [thisEvents.length]);

  const tableEvents = Array.isArray(thisEvents)
    ? (tableContentFilter && tableContentFilter.eventFilter
        ? tableContentFilter.eventFilter(thisEvents)
        : thisEvents
      ).filter(
        (_: any, i: number) =>
          (addingNewEvent && i !== 0) || // Dont show event being created in the list
          !addingNewEvent,
      )
    : [];

  // Set event range to be within limits if page width and therefore event limit changes
  React.useEffect(() => {
    if (Array.isArray(tableEvents)) {
      const diff = getEventLimit() - (eventRange[1] - eventRange[0]);
      if (diff !== 0 && tableEvents.length >= getEventLimit() + 1) {
        if (diff > 0 && eventRange[1] + 1 === tableEvents.length) setEventRange([eventRange[0] - diff, eventRange[1]]);
        else setEventRange([eventRange[0], eventRange[1] + diff]);
      }
    }
  }, [getEventLimit()]);

  // If the length of tableEvents is more than 4, set eventRange to the "end" of tableEvents by default
  React.useEffect(() => {
    Array.isArray(tableEvents) &&
      tableEvents.length > getEventLimit() &&
      !equals(eventRange, [tableEvents.length - (getEventLimit() + 1), tableEvents.length - 1]) &&
      setEventRange([tableEvents.length - (getEventLimit() + 1), tableEvents.length - 1]);
  }, [Array.isArray(tableEvents) && tableEvents.length]);

  // Track events length inside EventStepper so that the forms do not react immediately to the events changes
  const [eventsLength, setEventsLength] = React.useState<number>(thisEvents?.length || 0);

  React.useEffect(() => {
    if (thisEvents?.length > (eventsLength || 0)) {
      // Set editing new event if the events length has increased, meaning a new event has been added
      setEditingEvent(true);
    }
    //setEvents(thisEvents);
    setEventsLength(thisEvents?.length + readOnlyEvents.length);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [thisEvents?.length, readOnlyEvents.length]);

  const collapseLimit = previousEventsCollapseLimit ? previousEventsCollapseLimit + (editingEvent === true ? 1 : 0) : 0;

  return (
    <React.Fragment>
      <FormRow title={addNewTextHeader} condition={!viewing}>
        <React.Fragment>
          {isGroup && editingEventType
            ? React.isValidElement(props.propsByType[editingEventType].editingExtraElement)
              ? props.propsByType[editingEventType].editingExtraElement
              : typeof props.propsByType[editingEventType].editingExtraElement === 'function'
                ? props.propsByType[editingEventType].editingExtraElement(editingIndex ?? 0)
                : undefined
            : React.isValidElement(editingExtraElement)
              ? editingExtraElement
              : typeof editingExtraElement === 'function'
                ? editingExtraElement(editingIndex ?? 0)
                : undefined}
          {isGroup ? (
            <React.Fragment>
              {types.map((n, i) => {
                const someNewButtonHidden = types.map((t) => props.propsByType[t].hideNewButton);
                return (
                  // Render 'Add new' button if not editing and otherwise able (no concurrence etc)
                  props.propsByType[n].noConcurrence &&
                    !editing &&
                    thisEvents?.[0]?.startDate &&
                    dateNotValid(thisEvents?.[0]?.endDate)
                    ? props.propsByType[n].noConcurrenceText && (
                        <FormattedMessage key={n + i} id={props.propsByType[n].noConcurrenceText} />
                      )
                    : !addingNewEvent &&
                        !props.propsByType[n].hideNewButton &&
                        (props.propsByType[n].buttonDisabled &&
                        React.isValidElement(props.propsByType[n].buttonDisabledElement) ? (
                          <div
                            key={n + i}
                            style={{
                              marginBottom: types.length > 1 && i < types.length - 1 ? '2.5rem' : undefined,
                            }}
                          >
                            {props.propsByType[n].buttonDisabledElement}
                          </div>
                        ) : (
                          <ToolTip
                            key={n + i}
                            title={props.propsByType[n].buttonDisabledMessage}
                            content={
                              <React.Fragment>
                                <div
                                  id={'button'}
                                  style={{
                                    width: 'min-content',
                                    marginBottom:
                                      i < types.length - 1 && !someNewButtonHidden.some((bh) => bh)
                                        ? '4.5rem'
                                        : undefined,
                                  }}
                                >
                                  <ActionButton
                                    text={props.propsByType[n].addNewTextButton}
                                    onClick={
                                      props.propsByType[n].customCreateEventItem
                                        ? () => {
                                            setEditingEventType(n);
                                            props.propsByType[n].customCreateEventItem?.();
                                          }
                                        : () => {
                                            addNewExtraAction?.();
                                            setEditingEventType(n);
                                            createEventItem(
                                              n,
                                              setEditingEvent,
                                              props.propsByType[n].formData,
                                              props.propsByType[n].mutators?.creationMutator,
                                              props.propsByType[n].defaultValues,
                                            )();
                                          }
                                    }
                                    width={20}
                                    height={5}
                                    fontSize={18}
                                    disabled={props.propsByType[n].buttonDisabled}
                                  />
                                </div>
                              </React.Fragment>
                            }
                            hover={props.propsByType[n].buttonDisabled}
                            placement="top-start"
                            arrow={false}
                          />
                        ))
                );
              })}
            </React.Fragment>
          ) : // Render 'Add new' button if not editing and otherwise able (no concurrence etc)
          noConcurrence && !editing && thisEvents?.[0]?.startDate && dateNotValid(thisEvents?.[0]?.endDate) ? (
            noConcurrenceText && <FormattedMessage id={noConcurrenceText} />
          ) : (
            !addingNewEvent &&
            !hideNewButton &&
            (buttonDisabled && React.isValidElement(buttonDisabledElement) ? (
              buttonDisabledElement
            ) : (
              <ToolTip
                title={buttonDisabledMessage}
                content={
                  <React.Fragment>
                    <div id={'button'} style={{ width: 'min-content' }}>
                      <ActionButton
                        text={addNewTextButton}
                        onClick={
                          customCreateEventItem
                            ? () => customCreateEventItem()
                            : () => {
                                addNewExtraAction?.();
                                createEventItem(
                                  name,
                                  setEditingEvent,
                                  formData,
                                  mutators?.creationMutator,
                                  defaultValues,
                                )();
                              }
                        }
                        width={20}
                        height={5}
                        fontSize={18}
                        disabled={buttonDisabled}
                      />
                    </div>
                  </React.Fragment>
                }
                hover={buttonDisabled}
                placement="top-start"
                arrow={false}
              />
            ))
          )}
          {addingNewEvent ? (
            // Render editing event for new event
            <div style={{ marginTop: '2rem' }}>
              {isGroup && editingEventType ? (
                <EditingEvent
                  thisIndex={editingIndex ?? 0}
                  editingElements={props.propsByType[editingEventType].editingElements}
                  thisOnChange={onChangeUnit(
                    editingIndex ?? 0,
                    editingEventType,
                    props.propsByType[editingEventType].dateFieldName,
                    props.propsByType[editingEventType].formData,
                    editingEvents.current,
                    setEditingIndex,
                    props.propsByType[editingEventType].mutators?.onChangeMutator,
                  )}
                  cancel={cancelAddNew(undefined, editingEventType)}
                  save={() => save(editingEventType)}
                  saveDisabled={props.propsByType[editingEventType].saveDisabled}
                  saveDisabledMessage={props.propsByType[editingEventType].saveDisabledMessage}
                />
              ) : (
                <EditingEvent
                  thisIndex={editingIndex ?? 0}
                  editingElements={editingElements}
                  thisOnChange={onChangeUnit(
                    editingIndex ?? 0,
                    name,
                    dateFieldName,
                    formData,
                    editingEvents.current,
                    setEditingIndex,
                    mutators?.onChangeMutator,
                  )}
                  cancel={cancelAddNew()}
                  save={() => save()}
                  saveDisabled={saveDisabled}
                  saveDisabledMessage={saveDisabledMessage}
                />
              )}
            </div>
          ) : null}
        </React.Fragment>
      </FormRow>
      {/** This is used to draw a "no previous events -text in viewing mode if there are no events recorded" */}
      {viewing && !eventsLength && noPreviousEventsTextHeader && (
        <FormRow title={previousEventsTextHeader}>
          <FormattedMessage id={noPreviousEventsTextHeader} />
        </FormRow>
      )}
      {(eventsLength === 1 && addingNewEvent === false) || eventsLength > 1 || readOnlyEvents.length > 0 ? ( // Previous events
        <React.Fragment>
          {thisEvents?.length > 0 &&
            editingEvent !== true &&
            separateLatest && ( // Latest event
              <FormRow title={latestEventTextHeader ?? previousEventsTextHeader}>
                <StandAloneHeader>
                  <Container alignItems="center" style={{ height: '100%' }}>
                    <Item xs={6}>{stepLabelText(thisEvents?.[0])}</Item>
                    <Item xs={6}>
                      {!editing && !viewing && (
                        <HeaderControls
                          index={0}
                          type={thisEvents[0]._type}
                          setEditing={setEditing}
                          toggleMenu={toggleMenu(0)}
                          anchor={anchor}
                          openDeleteDialog={openDeleteDialog}
                        />
                      )}
                    </Item>
                  </Container>
                </StandAloneHeader>
                <StandAloneContent editing={editing && editingEvent === 0}>
                  {editingEvent !== 0 ? (
                    <div style={{ margin: '' }}>
                      {isGroup
                        ? props.propsByType[thisEvents[0]._type].stepContent(omit(['_type'], thisEvents[0]))
                        : stepContent(omit(['_type'], thisEvents[0]))}
                    </div>
                  ) : isGroup && editingEventType ? (
                    <EditingEvent
                      thisIndex={editingIndex ?? 0}
                      editingElements={props.propsByType[editingEventType].editingElements}
                      thisOnChange={onChangeUnit(
                        editingIndex ?? 0,
                        editingEventType,
                        props.propsByType[editingEventType].dateFieldName,
                        props.propsByType[editingEventType].formData,
                        editingEvents.current,
                        setEditingIndex,
                        props.propsByType[editingEventType].mutators?.onChangeMutator,
                      )}
                      save={() => save(editingEventType)}
                      saveDisabled={props.propsByType[editingEventType].saveDisabled}
                      saveDisabledMessage={props.propsByType[editingEventType].saveDisabledMessage}
                    />
                  ) : (
                    <EditingEvent
                      thisIndex={editingIndex ?? 0}
                      editingElements={editingElements}
                      thisOnChange={onChangeUnit(
                        editingIndex ?? 0,
                        name,
                        dateFieldName,
                        formData,
                        editingEvents.current,
                        setEditingIndex,
                        mutators?.onChangeMutator,
                      )}
                      save={() => save()}
                      saveDisabled={saveDisabled}
                      saveDisabledMessage={saveDisabledMessage}
                    />
                  )}
                </StandAloneContent>
              </FormRow>
            )}
          {tableContent && (
            <Container style={{ height: '3rem' }}>
              {typeof editingEvent !== 'number' && !addingNewEvent && (
                <React.Fragment>
                  <Item xs={5} style={{ color: colors.tertiaryText }}>
                    <FormattedMessage id={previousEventsTextHeader} />
                  </Item>
                  <Item xs={true}>
                    <StandAloneHeader style={{ backgroundColor: 'transparent' }}>
                      <Container>
                        <Item xs={6} />
                        <Item xs={6} style={{ textAlign: 'right' }}>
                          <Container justifyContent="flex-end">
                            <Item style={{ fontWeight: 'normal' }}>
                              {['list', 'table'].map((v, i) => (
                                <React.Fragment key={v}>
                                  <StyledViewSelector
                                    style={{ paddingRight: i === 0 ? '0.5rem' : undefined }}
                                    onClick={(): void => setActiveView(v as TTableView)}
                                    active={activeView === v}
                                  >
                                    <FormattedMessage id={`general.${v}`} />
                                  </StyledViewSelector>
                                  {i > 0 && ' '}
                                </React.Fragment>
                              ))}
                            </Item>
                          </Container>
                        </Item>
                      </Container>
                    </StandAloneHeader>
                  </Item>
                </React.Fragment>
              )}
            </Container>
          )}
          {
            {
              list: (
                <FormRow
                  title={
                    tableContent && typeof editingEvent !== 'number' && !addingNewEvent
                      ? undefined
                      : previousEventsTextHeader
                  }
                >
                  <React.Fragment>
                    {thisEventsLength === thisEvents.length &&
                      (collapseLimit && thisEvents.slice(collapseLimit).length - (editingEvent ? 1 : 0) > 0
                        ? ['visible', 'collapse']
                        : ['visible']
                      ).map((display) => {
                        const allEvents = [...thisEvents, ...readOnlyEvents];
                        return (
                          <ConditionalCollapse
                            key={display}
                            condition={display === 'collapse'}
                            localeIDs={previousEventsCollapseMessage as { showMessage: string; hideMessage: string }}
                            amount={thisEvents.slice(collapseLimit).length - 1}
                          >
                            <Stepper
                              activeStep={activeStep}
                              orientation="vertical"
                              style={{ margin: '0', padding: '0' }}
                              connector={<Connector completed={false} />}
                            >
                              {allEvents?.map((d: any, i: number) => {
                                if (
                                  (addingNewEvent && i === 0 && allEvents?.length === 1) || // If we want to create first event automatically and start editing after creation (see MS type)
                                  (addingNewEvent && i !== 0) || // Dont show event being created in the list
                                  !addingNewEvent
                                ) {
                                  if ((separateLatest && i > 0) || !separateLatest) {
                                    // Used to update step label while editing
                                    const stepLabelTextDoc = exists(editingIndex)
                                      ? _thisEvents.find((e) => e.id === d.id)
                                      : d;
                                    return (
                                      <Step
                                        key={d._type ? d._type + i : i}
                                        style={{
                                          marginBottom: '0',
                                          display:
                                            collapseLimit &&
                                            ((i > collapseLimit - 1 && display === 'visible') ||
                                              (i < collapseLimit && display === 'collapse'))
                                              ? 'none'
                                              : undefined,
                                        }}
                                      >
                                        <StyledStepLabel
                                          StepIconComponent={IconChooser}
                                          style={{
                                            padding: '0',
                                            backgroundColor: isGroup
                                              ? props.propsByType[d._type].theme?.highlightColor
                                              : theme?.highlightColor,
                                          }}
                                          // Dont allow opening of other events while editing is active. Otherwise it is bugged
                                          onClick={!editing ? activateStep(i, allClosed) : undefined}
                                        >
                                          <Container alignItems="center">
                                            <Item xs={6}>
                                              <StyledLabelText>
                                                {isGroup
                                                  ? props.propsByType[d._type].stepLabelText(stepLabelTextDoc)
                                                  : stepLabelText(stepLabelTextDoc)}
                                              </StyledLabelText>
                                            </Item>
                                            <Item xs={6}>
                                              {!editing && !viewing && (
                                                <HeaderControls
                                                  index={i}
                                                  type={d._type}
                                                  setEditing={setEditing}
                                                  toggleMenu={toggleMenu(i)}
                                                  anchor={anchor}
                                                  openDeleteDialog={openDeleteDialog}
                                                />
                                              )}
                                            </Item>
                                          </Container>
                                        </StyledStepLabel>
                                        <StyledStepContent editing={editing && editingEvent === i}>
                                          {editingEvent !== i ? (
                                            <div style={{ margin: '' }}>
                                              {isGroup
                                                ? props.propsByType[d._type].stepContent(omit(['_type'], d))
                                                : stepContent(omit(['_type'], d))}
                                            </div>
                                          ) : null}
                                          {editing && editingEvent === i ? (
                                            isGroup ? (
                                              <EditingEvent
                                                thisIndex={
                                                  editingIndex ??
                                                  props.propsByType[d._type].formData.document[d._type].findIndex(
                                                    (e: any) => thisEvents[i].id === e.id,
                                                  )
                                                }
                                                editingElements={props.propsByType[d._type].editingElements}
                                                thisOnChange={onChangeUnit(
                                                  editingIndex ??
                                                    props.propsByType[d._type].formData.document[d._type].findIndex(
                                                      (e: any) => thisEvents[i].id === e.id,
                                                    ),
                                                  d._type,
                                                  props.propsByType[d._type].dateFieldName,
                                                  props.propsByType[d._type].formData,
                                                  editingEvents.current,
                                                  setEditingIndex,
                                                  props.propsByType[d._type].mutators?.onChangeMutator,
                                                )}
                                                save={() => save(d._type)}
                                                saveDisabled={props.propsByType[d._type].saveDisabled}
                                                saveDisabledMessage={props.propsByType[d._type].saveDisabledMessage}
                                              />
                                            ) : (
                                              <EditingEvent
                                                thisIndex={editingIndex ?? i}
                                                editingElements={editingElements}
                                                thisOnChange={onChangeUnit(
                                                  editingIndex ?? i,
                                                  name,
                                                  dateFieldName,
                                                  formData,
                                                  editingEvents.current,
                                                  setEditingIndex,
                                                  mutators?.onChangeMutator,
                                                )}
                                                save={save}
                                                saveDisabled={saveDisabled}
                                                saveDisabledMessage={saveDisabledMessage}
                                              />
                                            )
                                          ) : null}
                                        </StyledStepContent>
                                      </Step>
                                    );
                                  }
                                  return undefined;
                                } else return undefined;
                              })}
                            </Stepper>
                          </ConditionalCollapse>
                        );
                      })}
                  </React.Fragment>
                  {
                    {
                      default: (
                        <DeleteDialog
                          open={exists(deleteIndex)}
                          text={<FormattedMessage id={deleteMessage ? deleteMessage : 'general.reallyWantToDelete'} />}
                          confirm={{ callback: () => deleteConfirmCallback() }}
                          cancel={{ callback: deleteCancelCallback }}
                        />
                      ),
                      full: (
                        <FullDeleteDialog
                          document={controlProps.reduce((current, key) => {
                            (current as { [key: string]: any })[key] = null;
                            return current;
                          }, {} as IControlProps)}
                          open={exists(deleteIndex)}
                          setOpen={() => setDeleteIndex(null)}
                          customDeleteHandler={(details?: IDeleteDetails) => {
                            const orgId = getJWTData()?.orgid;
                            const userId = getJWTData()?.useruuid;
                            if (orgId && userId) {
                              const removeTS = new Date().getTime();
                              const removeInfo = {
                                removeDate: new Date().toISOString(),
                                remover: userId,
                                removerOrg: orgId,
                                removeReason: details,
                              };
                              deleteConfirmCallback({ removeTS: removeTS, removeInfo: removeInfo });
                            }
                          }}
                        />
                      ),
                    }[
                      isGroup && editingEventType
                        ? props.propsByType[editingEventType].deleteDialogMode ?? 'default'
                        : deleteDialogMode
                    ]
                  }
                </FormRow>
              ),
              table: tableContent && (
                <React.Fragment>
                  <Container
                    alignItems="center"
                    style={{
                      backgroundColor: colors.lightGray,
                      fontWeight: 600,
                      height: '5rem',
                      marginBottom: '1rem',
                    }}
                  >
                    <Item xs={3} style={{ paddingLeft: '2rem' }}>
                      {tableContentFilter && tableContentFilter.input}
                    </Item>
                    {tableEvents.slice(eventRange[0], eventRange[1] + 1).map((e: any, i: number, arr: any[]) => (
                      <Item key={i} xs={i === arr.length - 1 ? 1 : 2}>
                        {stepLabelText(e)}
                      </Item>
                    ))}
                    <Item xs={true}>
                      {tableEvents.length > 1 && (
                        <StyledTableControls
                          eventRange={eventRange}
                          tableEvents={tableEvents}
                          changeEventRange={changeEventRange}
                        />
                      )}
                    </Item>
                  </Container>
                  {tableContent(tableEvents.slice(eventRange[0], eventRange[1] + 1))}
                </React.Fragment>
              ),
            }[activeView]
          }
        </React.Fragment>
      ) : undefined}
    </React.Fragment>
  );
};

const EventStepper = (props: IEventStepper): JSX.Element => <_EventStepper type="single" {...props} />;

export const EventStepperGroup = ({
  children,
  previousEventsCollapseLimit,
  previousEventsCollapseMessage,
  allClosed,
}: IEventStepperGroup): JSX.Element => {
  const namesAndProps = children.map((child) => ({ name: child.props.name, props: omit(['name'], child.props) }));

  const propsByType = namesAndProps.reduce(
    (current, key) => {
      (current as { [key: string]: any })[key.name] = key.props;
      return current;
    },
    {} as { [key: string]: IEventStepper },
  );

  if (isEmpty(propsByType)) return <></>;

  if (previousEventsCollapseLimit) {
    propsByType[Object.keys(propsByType)[0]].previousEventsCollapseLimit = previousEventsCollapseLimit;
    propsByType[Object.keys(propsByType)[0]].previousEventsCollapseMessage = previousEventsCollapseMessage ?? {
      showMessage: 'general.show',
      hideMessage: 'general.hide',
    };
  }
  if (allClosed) {
    propsByType[Object.keys(propsByType)[0]].allClosed = allClosed;
  }

  // Combine events of different types to one array
  const combinedEvents: any[] = Object.keys(propsByType)
    // _type property is internal to EventStepper, used to distinct different types of events
    .map((n) => propsByType[n].formData.document?.[n]?.map((e: any) => ({ ...e, _type: n })))
    .flat()
    .filter((e) => e);

  return <_EventStepper type="group" propsByType={propsByType} combinedEvents={combinedEvents} />;
};

type TTableView = 'list' | 'table';

type TEventStepperProps =
  | ({ type: 'single' } & IEventStepper)
  | ({ type: 'group' } & {
      /** First property defines default/main event type, following ones are "additional" types */
      propsByType: { [key: string]: IEventStepper };
      /** Events of different types in EventStepper group combined */
      combinedEvents: any[];
    });

interface IEventStepper extends IOwnProps {
  /** Set a limit after which previous events are collapsed */
  previousEventsCollapseLimit?: number;
  /** Message to show when previous events are collapsed */
  previousEventsCollapseMessage?: { showMessage: string; hideMessage: string };
}

interface IEventStepperGroup
  extends Pick<IEventStepper, 'previousEventsCollapseLimit' | 'previousEventsCollapseMessage' | 'allClosed'> {
  /**
   * EventSteppers of different types
   *  - Table mode not supported!
   */
  children: Array<JSX.Element>;
}

interface IOwnProps {
  /** Name of the event array in formData */
  name: string;
  /** Form data */
  formData: IFormData<any>;
  /** Indicates if a document on which eventstepper is used, is being just viewed */
  viewing?: boolean;
  /** Content shown in stepper label (usually date) */
  stepLabelText: (d: any) => string | JSX.Element;
  /** Content shown in stepper steps (usually event item data and formatting) Use StepperHeaderValuePairs */
  stepContent: (d: any) => JSX.Element; // StepperHeaderValuePairs
  /** Show the latest/first step separately from others */
  separateLatest?: boolean;
  /** All steps closed by default and allow closing all steps */
  allClosed?: true;
  /** Locale id for FormRow header */
  addNewTextHeader: string;
  /** Locale id for add new button */
  addNewTextButton: string;
  /** Condition to disable add new button */
  buttonDisabled?: boolean;
  /** Hover message to show when add new button is disabled */
  buttonDisabledMessage?: string | JSX.Element;
  /** Custom element to show in place of add new button when it is disabled */
  buttonDisabledElement?: JSX.Element;
  /** Condition to disable save/accept button */
  saveDisabled?: boolean;
  /** Message to show when save/accept button is disabled */
  saveDisabledMessage?: string | JSX.Element;
  /** Locale id for previous events FormRow header */
  latestEventTextHeader?: string;
  /** Locale id for previous events FormRow header */
  previousEventsTextHeader: string;
  /** When viewing prop is provided, viewing is true and there are no events, this text will be shown */
  noPreviousEventsTextHeader?: string;
  /** Element for event editing (InputHandlers and such) Use StepperHeaderFormInputPairs if possible */
  editingElements: (i: number, onChange: IFormData['onChange']) => JSX.Element; // StepperHeaderFormInputPairs
  /** Additional element to show above editing element */
  editingExtraElement?: JSX.Element | ((i: number) => JSX.Element);
  /** True if there can only be one current event (needs an endDate field, false by default) */
  noConcurrence?: boolean;
  /** Locale id for text shown instead of add new button when there is an active event and noConcurrence is true */
  noConcurrenceText?: string;
  /** Used to enable editing mode on by default  */
  editingModeOn?: boolean;
  /** Can be used to override default action when creating new event  */
  customCreateEventItem?: () => void;
  mutators?: {
    /** Possible event mutators triggered when making changes to the event  */
    onChangeMutator?: (event: any, name?: string, value?: any) => any;
    /** Possible creation mutators triggered when creating a new event  */
    creationMutator?: (event: any) => any;
  };
  /** Name for the date field events are sorted by */
  dateFieldName?: string;
  /**
   * Choose which delete dialog to show, defaults to 'default'
   * Note that 'full' dialog does not actually delete events but labels them with removeTS and removeInfo
   */
  deleteDialogMode?: 'default' | 'full';
  /** Custom message id for delete dialog */
  deleteMessage?: string;
  /** Default values to insert into an event when it is created (NOTE: required when 2 or more default values are needed) */
  defaultValues?: { [field: string]: TFieldValue };
  /** Function for making actions when deleting an event */
  deleteAction?: (index: number, formData: IFormData<any, any>) => () => void;
  saveExtraAction?: () => void;
  cancelExtraAction?: () => void;
  addNewExtraAction?: () => void;
  startEditExtraAction?: () => void;
  /** Content shown in table view (usually event item data and formatting) - uses stepLabelText as event headers */
  tableContent?: (d: any) => JSX.Element;
  /** For filtering events displayed in table view */
  tableContentFilter?: {
    /** Input component used to set the filter - placed in table header bar */
    input?: JSX.Element;
    /** Filter function that returns the filtered events */
    eventFilter?: (d: any) => any[];
  };
  /** For theming the events. Use to distinct different types of events etc, extend this as needed */
  theme?: { highlightColor?: string };
  /** Hide adding new event button, used for example with <EventStepperGroup> element to show only one new button at a time */
  hideNewButton?: boolean;
  defaultView?: TTableView;
  readOnlyEvents?: any[];
}

export default EventStepper;
