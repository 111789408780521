import * as React from 'react';
import EventStepper, { EventStepperGroup } from '../../../../../components/EventStepper';
import InputHandler from '../../../../../components/InputHandler';
import { formatPartialDate, nowPartialDate, partialDateToValue } from 'neuro-utils';
import { StepperHeaderFormInputPair, StepperHeaderValuePair } from 'Components/EventStepper/components';
import Treatments from './components/Treatments';
import { TDCSContext } from '../..';
import TabContent from 'Components/TabContent';
import Treatment from './components/Treatment';
import { equals } from 'ramda';
import {
  addingTreatmentSessionDisabledReason,
  findPrecedingDoctorsOrder,
  getSessionNumber,
  subjectOfTreatmentTitle,
} from 'Utility/ninmtUtil';
import { RenderAlerts } from 'Components/Alerts';
import PatientsRatings from './components/PatientsRatings';
import colors from '../../../../../config/theme/colors';
import FormRow from 'Components/FormRow';
import ActionButtonRounded from 'Components/ActionButtonRounded';
import TasklistDialog from './components/TaskListDialog';
import { styled } from '@mui/system';
import { actions } from 'Store/myapp/actions';
import { useDispatch } from 'react-redux';
import { Task_Progress } from 'neuro-schemas';

const StyledRow = styled('div')({
  display: 'grid',
  gridTemplateColumns: '10fr 2fr',
  width: '100%',
});

type TTreatmentEfficiencyState =
  | 'treatmentEfficiencyNotStarted'
  | 'treatmentEfficiencyBeforePending'
  | 'treatmentEfficiencyBeforeCompleted'
  | 'treatmentEfficiencyAfterPending'
  | 'treatmentEfficiencyAfterCompleted'
  | undefined;

const Sessions = (): JSX.Element => {
  const tdcsContext = React.useContext(TDCSContext);
  const {
    formData,
    fm,
    doctorsOrders,
    contraIndications,
    symptomsAndLocations,
    setDoctorsOrdersReview,
    setAnchor,
    patientMySQStatus,
    taskLists,
    sortedAndMergedMyDocuments,
  } = tdcsContext;

  const dispatch = useDispatch();

  const currentTreatmentEfficiencyDocs = sortedAndMergedMyDocuments.filter(
    (d) => partialDateToValue(d.date) === partialDateToValue(nowPartialDate()) && d._type === 'treatmentEfficiency',
  );

  const [loading, setLoading] = React.useState<boolean>(false);

  const fetchMyAppData = React.useCallback(() => actions.loadMyAppData(dispatch), [dispatch]);

  const currentTaskLists = taskLists.filter(
    (tl) =>
      tl.tasks?.some((task) => task.indexOf('treatmentEfficiency') > -1) &&
      partialDateToValue(tl.sendDate) === partialDateToValue(nowPartialDate()),
  );

  // Active tasklists are those which do not have 1ms after midnight as their deadline
  const activeTaskLists = currentTaskLists.filter((tl) => tl.end_time !== partialDateToValue(nowPartialDate()) + 1);

  // Reset tasklist deadlines to 1ms after midnight to make them inactive
  const closeTaskLists = () => {
    const beforeTaskList = activeTaskLists.find((tl) => tl.tasks?.includes('treatmentEfficiencyBefore'));
    const afterTaskList = activeTaskLists.find((tl) => tl.tasks?.includes('treatmentEfficiencyAfter'));
    if (beforeTaskList && afterTaskList) {
      actions
        .updateTasklist({ ...beforeTaskList, end_time: partialDateToValue(nowPartialDate()) + 1 }, dispatch)
        .then((res) => {
          if (res) {
            actions
              .updateTasklist({ ...afterTaskList, end_time: partialDateToValue(nowPartialDate()) + 1 }, dispatch)
              .then(() => {
                return;
              })
              .catch(() => {
                return;
              });
          }
          return;
        })
        .catch(() => {
          return;
        });
    } else if (beforeTaskList || afterTaskList) {
      const taskList = beforeTaskList || afterTaskList;
      actions
        .updateTasklist({ ...taskList!, end_time: partialDateToValue(nowPartialDate()) + 1 }, dispatch)
        .then(() => {
          return;
        })
        .catch(() => {
          return;
        });
    }
  };

  const refreshMyAppData = (): void => {
    setLoading(true);
    fetchMyAppData().then(() => {
      setLoading(false);
    });
  };

  const [taskListDialogOpen, setTaskListDialogOpen] = React.useState<boolean>(false);

  const getTreatmentEfficiencyState = (): TTreatmentEfficiencyState => {
    // Some tasklists have been sent --> treatment efficiency procedure has started
    if (currentTaskLists.length > 0) {
      // Some surveys have been filled --> the "after treatment" survey is either not started, pending or completed
      if (currentTreatmentEfficiencyDocs.length > 0) {
        const before = currentTreatmentEfficiencyDocs.find((d) => d.timing === 'before');
        const after = currentTreatmentEfficiencyDocs.find((d) => d.timing === 'after');

        const beforeCompleted =
          before &&
          Task_Progress.calculateProgress('treatmentEfficiency', before).state === Task_Progress.State.Completed;
        const afterCompleted =
          after &&
          Task_Progress.calculateProgress('treatmentEfficiency', after).state === Task_Progress.State.Completed;

        if (beforeCompleted) {
          // The "after treatment" survey is completed --> treatment efficiency procedure is completed
          if (afterCompleted) return 'treatmentEfficiencyAfterCompleted';
          if (activeTaskLists.some((tl) => tl.tasks?.some((task) => task.indexOf('treatmentEfficiencyAfter') > -1))) {
            // The "before treatment" survey is completed and a new tasklist has been sent --> the "after treatment" survey is pending
            return 'treatmentEfficiencyAfterPending';
          }
          // The "before treatment" survey is completed and a new tasklist has not been sent --> the "after treatment" survey is not started
          return 'treatmentEfficiencyBeforeCompleted';
        }
      }
      if (activeTaskLists.some((tl) => tl.tasks?.some((task) => task.indexOf('treatmentEfficiencyBefore') > -1))) {
        // No completed treatment efficiency surveys --> the "before treatment" survey is pending
        return 'treatmentEfficiencyBeforePending';
      }
      // Tasklists have been sent but none of them is active --> treatment efficiency procedure has not started
      return 'treatmentEfficiencyNotStarted';
    }
    // No tasklists have been sent --> treatment efficiency procedure has not started
    return 'treatmentEfficiencyNotStarted';
  };

  const [treatmentEfficiencyState, setTreatmentEfficiencyState] =
    React.useState<TTreatmentEfficiencyState>(getTreatmentEfficiencyState());

  // Refresh treatment efficiency state
  React.useEffect(() => {
    setTreatmentEfficiencyState(getTreatmentEfficiencyState());
  }, [taskLists, sortedAndMergedMyDocuments]);

  // Close tasklists when treatment efficiency procedure is completed
  React.useEffect(() => {
    if (treatmentEfficiencyState === 'treatmentEfficiencyAfterCompleted' && activeTaskLists.length > 0) {
      closeTaskLists();
    }
  }, [treatmentEfficiencyState]);

  // If all doctor's orders are "completed", no review is required
  const noReviewRequired = doctorsOrders.every((d) => d.completed);

  const buttonDisabledReason = addingTreatmentSessionDisabledReason('tdcs', doctorsOrders, contraIndications, formData);

  // Add session createDate for more accurate sorting
  const creationMutator = (
    event: ITDCSSession | ITDCSUnusedSession | ITDCSOtherEvent,
  ): ITDCSSession | ITDCSUnusedSession | ITDCSOtherEvent => {
    return { ...event, createDate: Date.now() };
  };

  // Optionally require subjects of treatment etc.
  const precedingDoctorsOrder = (index?: number, reqFields?: 'endTDCS'[]) =>
    findPrecedingDoctorsOrder('tdcs', doctorsOrders, formData.document.sessions ?? [], index ?? 0, reqFields);

  const precedingDocOrdBeforeAddingNewSes = React.useRef<IDoctorsOrder | undefined>(precedingDoctorsOrder(0, []));

  const [dateDefault, setDateDefault] = React.useState<'now' | PartialDate>('now');
  const [eventType, setEventType] = React.useState<string | undefined>(undefined);
  const [disableType, setDisableType] = React.useState<boolean>(false);

  const handleSessionTypeChange = (values: TOnChangeValues) => {
    const value = Object.values(values)?.[0];
    if (typeof value === 'string') setEventType(value);
  };

  // Capping the session date
  React.useEffect(() => {
    if (precedingDocOrdBeforeAddingNewSes.current && precedingDocOrdBeforeAddingNewSes.current.tdcs?.endTDCS) {
      setDateDefault(precedingDocOrdBeforeAddingNewSes.current.date ?? 'now');
    } else {
      // If preceding order document has not ended tdcs but future one has ended cap it to that
      const futureOrders = doctorsOrders.filter(
        (d) =>
          partialDateToValue(d.date) >
          partialDateToValue(formData.document.sessions?.[0]?.date ?? formData.document.date),
      );
      setDateDefault(futureOrders.find((o) => o.tdcs?.endTDCS)?.date ?? 'now');
    }
  }, [precedingDocOrdBeforeAddingNewSes.current, formData.document.date]);

  const hasDeletedSessions =
    Array.isArray(formData.document.sessions) &&
    formData.document.sessions.length > 0 &&
    formData.document.sessions.some((s) => s.removeTS || s.removeInfo);

  // Hide/filter deleted sessions
  React.useEffect(() => {
    if (hasDeletedSessions) {
      formData.onChange?.({ sessions: formData.document.sessions?.filter((s) => !(s.removeTS || s.removeInfo)) });
    }
  }, [hasDeletedSessions]);

  return (
    <React.Fragment>
      <FormRow title="tdcs.newSession">
        <InputHandler
          name="typeOfEvent"
          type="Radio"
          options={['treatmentMonitoring', 'doctorInterview', 'nurseReception', 'session', 'unusedSession']}
          editing={true}
          formData={{
            document: { typeOfEvent: eventType },
            onChange: handleSessionTypeChange,
          }}
          optionFormatter={(o) => fm(`tdcs.opts.eventTypes.${o}`)}
          disabledOptions={
            disableType
              ? ['treatmentMonitoring', 'doctorInterview', 'nurseReception', 'session', 'unusedSession']
              : undefined
          }
        />
      </FormRow>
      <EventStepperGroup
        allClosed
        previousEventsCollapseLimit={5}
        previousEventsCollapseMessage={{ showMessage: 'tdcs.showAllSessions', hideMessage: 'tdcs.hideAllSessions' }}
      >
        <EventStepper
          name="sessions"
          formData={formData}
          mutators={{ creationMutator }}
          customCreateEventItem={
            noReviewRequired
              ? undefined
              : () => {
                  // If all doctor's orders are not completed, review is required
                  setAnchor('sessionsAndProtocols');
                  setDoctorsOrdersReview({ active: true, completed: false });
                }
          }
          deleteDialogMode="full"
          stepLabelText={(d: ITDCSSession): string =>
            `${formatPartialDate(d.date)} - ${fm('tdcs.opts.eventTypes.session')}`
          }
          stepContent={(d: ITDCSSession): JSX.Element => (
            <React.Fragment>
              {['patientsRating', 'additionalInformation', 'subjectOfTreatment'].map((key, index) => {
                const currentIndex = formData.document.sessions?.findIndex((s) => equals(s, d));
                const value = d[key as keyof ITDCSSession];
                switch (key) {
                  case 'patientsRating': {
                    return (value as [])?.filter((s) => s).length > 0 || !Array.isArray(d.patientsRating) ? (
                      <React.Fragment key={`${key}${index}`}>
                        <StepperHeaderValuePair
                          header={
                            <div style={{ whiteSpace: 'nowrap', fontWeight: 400 }}>
                              {fm('tdcs.patientsRating.title')}
                            </div>
                          }
                          value={''}
                        />
                        <div style={{ margin: '2rem 0 2rem -2.1rem' }}>
                          <PatientsRatings
                            editIndex={currentIndex ?? 0}
                            editingEvent={false}
                            formData={formData}
                            fm={fm}
                            symptomsAndLocations={symptomsAndLocations}
                            sessionDate={d.date}
                          />
                        </div>
                      </React.Fragment>
                    ) : (
                      <React.Fragment key={`${key}${index}`} />
                    );
                  }
                  case 'subjectOfTreatment': {
                    const currentIndex = formData.document.sessions?.findIndex((s) => equals(s, d));
                    return (value as [])?.filter((s) => s).length > 0 ? (
                      <React.Fragment key={`${key}${index}`}>
                        <StepperHeaderValuePair header={fm('tdcs.subjectOfTreatment.title')} value={''} />
                        <div style={{ margin: '2rem 0 2rem -2.1rem' }}>
                          <TabContent>
                            {(value as [])?.map((s: ITDCSTreatment, i: number) => ({
                              key: `${s.id}${i}`,
                              id: `${s.id}Tab${i}`,
                              title: subjectOfTreatmentTitle(s, 'tdcs', fm),
                              ended: s.deleted,
                              content: (
                                <Treatment
                                  document={s}
                                  onChange={(): string => ''}
                                  session={d}
                                  sessionNumber={getSessionNumber(
                                    formData.document?.sessions ?? [],
                                    currentIndex ?? 0,
                                    s,
                                  )}
                                  precedingDoctorsOrder={precedingDoctorsOrder(currentIndex)}
                                  editingEvent={false}
                                />
                              ),
                              count: i,
                            }))}
                          </TabContent>
                        </div>
                      </React.Fragment>
                    ) : (
                      <React.Fragment key={`${key}${index}`} />
                    );
                  }
                  default: {
                    return (
                      <StepperHeaderValuePair
                        key={`${key}${index}`}
                        header={fm(`tdcs.${key === 'additionalInformation' ? 'additionalInformationSession' : key}`)}
                        value={value || '-'}
                      />
                    );
                  }
                }
              })}
            </React.Fragment>
          )}
          addNewTextHeader=""
          addNewTextButton="tdcs.newSession"
          buttonDisabled={buttonDisabledReason ? true : eventType !== 'session' ? true : false}
          hideNewButton={!(eventType === 'session' || !eventType)}
          buttonDisabledMessage={
            eventType !== 'session' && !buttonDisabledReason ? fm('tdcs.chooseEventType') : undefined
          }
          buttonDisabledElement={
            buttonDisabledReason ? (
              <RenderAlerts
                alerts={[{ id: 'tdcsAlert', textID: `tdcs.${buttonDisabledReason}Message`, severity: 'info' }]}
                elevation={0}
                width={80}
              />
            ) : undefined
          }
          cancelExtraAction={() => {
            setDisableType(false);
            setEventType(undefined);
          }}
          saveExtraAction={() => {
            setDisableType(false);
            setEventType(undefined);
          }}
          addNewExtraAction={() => setDisableType(true)}
          startEditExtraAction={() => setDisableType(true)}
          previousEventsTextHeader="tdcs.previousSessions"
          editingElements={(index: number, onChange: IFormData['onChange']): JSX.Element => {
            return (
              <React.Fragment>
                <StepperHeaderFormInputPair
                  header={fm('general.date')}
                  input={
                    <InputHandler
                      type="PartialDate"
                      editing={true}
                      name="date"
                      formData={{
                        onChange,
                        document: { date: formData.document.sessions?.[index]?.date || '' },
                      }}
                      dateDefault={dateDefault}
                      isNotCancellable={true}
                      dateHook={{
                        dateHookFloor: formData.document.date,
                        dateHookCeiling: precedingDoctorsOrder(0, [])?.tdcs?.endTDCS
                          ? precedingDoctorsOrder(0, [])?.date
                          : undefined,
                      }}
                    />
                  }
                />
                <StepperHeaderFormInputPair
                  header={<div style={{ whiteSpace: 'nowrap' }}>{fm('tdcs.patientsRating.title')}</div>}
                  input={<></>}
                />
                <div style={{ margin: '2rem 0 2rem -2.1rem' }}>
                  <PatientsRatings
                    editIndex={index}
                    formData={formData}
                    fm={fm}
                    symptomsAndLocations={symptomsAndLocations}
                    sessionDate={formData.document.sessions?.[index]?.date}
                  />
                </div>
                <StepperHeaderFormInputPair
                  header={fm('tdcs.additionalInformationSession')}
                  input={
                    <InputHandler
                      type="TextArea"
                      editing={true}
                      name="additionalInformation"
                      formData={{
                        onChange,
                        document: {
                          additionalInformation: formData.document.sessions?.[index]?.additionalInformation || '',
                        },
                      }}
                      placeholder="tdcs.additionalInformationSession"
                    />
                  }
                />
                <StepperHeaderFormInputPair header={fm('tdcs.subjectOfTreatment.title')} input={<></>} />
                <div style={{ margin: '2rem 0 2rem -2.1rem' }}>
                  <Treatments editIndex={index} formData={formData} fm={fm} doctorsOrders={doctorsOrders} />
                </div>
              </React.Fragment>
            );
          }}
          editingExtraElement={(index: number) => {
            const state = treatmentEfficiencyState;

            // Should not be able to send tasklists if both before and after surveys have been filled
            if (state === 'treatmentEfficiencyAfterCompleted') return <React.Fragment />;

            // Should not be able to send tasklists if patient has not been invited to use My service or if no symptoms and locations
            if (!patientMySQStatus || symptomsAndLocations?.length === 0) return <React.Fragment />;

            const currentSession = formData.document?.sessions?.[index];

            // Should not be able to send tasklists if session is not on current day (?)
            if (!currentSession || partialDateToValue(currentSession.date) !== partialDateToValue(nowPartialDate())) {
              return <React.Fragment />;
            }

            let text;
            let buttonText;
            let buttonAction;

            switch (state) {
              case 'treatmentEfficiencyNotStarted':
                text = 'tdcs.treatmentEfficiencyBeforeInfo';
                buttonText = 'myService.newTaskList';
                buttonAction = () => setTaskListDialogOpen(true);
                break;
              case 'treatmentEfficiencyBeforePending':
                text = 'tdcs.treatmentEfficiencyBeforeActiveInfo';
                buttonText = 'tdcs.update';
                buttonAction = () => refreshMyAppData();
                break;
              case 'treatmentEfficiencyAfterPending':
                text = 'tdcs.treatmentEfficiencyAfterActiveInfo';
                buttonText = 'tdcs.update';
                buttonAction = () => refreshMyAppData();
                break;
              case 'treatmentEfficiencyBeforeCompleted':
                text = 'tdcs.treatmentEfficiencyAfterInfo';
                buttonText = 'myService.newTaskList';
                buttonAction = () => setTaskListDialogOpen(true);
                break;
              // Unused case - element should not be rendered
              default:
                text = 'tdcs.treatmentEfficiencyBeforeInfo';
                buttonText = 'myService.newTaskList';
                buttonAction = () => setTaskListDialogOpen(true);
            }

            return (
              <div style={{ margin: '2rem 0 2rem 0' }}>
                <RenderAlerts
                  alerts={[
                    {
                      id: 'treatmentEfficiencyAlert',
                      textID: '',
                      customText: (
                        <React.Fragment>
                          <StyledRow style={{ marginBottom: '2rem' }}>
                            <div style={{ gridColumn: '1 / span 2' }}>{fm(text)}</div>
                          </StyledRow>
                          <StyledRow>
                            <div />
                            <ActionButtonRounded
                              text={buttonText}
                              onClick={buttonAction}
                              loading={loading}
                              colorScheme="dark"
                              uppercase={false}
                              fontSize={16}
                              height={4}
                              width={20}
                            />
                          </StyledRow>
                        </React.Fragment>
                      ),
                      severity: 'info',
                    },
                  ]}
                  elevation={0}
                  width={80}
                />
              </div>
            );
          }}
        />
        <EventStepper
          name="unusedSessions"
          formData={formData}
          mutators={{ creationMutator }}
          stepLabelText={(d: ITDCSUnusedSession): string =>
            `${formatPartialDate(d.date)} - ${fm('tdcs.opts.eventTypes.unusedSession')}`
          }
          stepContent={(d: ITDCSUnusedSession): JSX.Element => (
            <React.Fragment>
              <StepperHeaderValuePair
                header={fm('tdcs.unusedSessionReason')}
                value={d.reason ? fm(`tdcs.opts.unusedSessionReason.${d.reason}`) : '-'}
              />
              {d.reason && (
                <React.Fragment>
                  <StepperHeaderValuePair
                    header={fm('tdcs.unusedSessionReasonDetails')}
                    value={d.reasonDetails ? fm(`tdcs.opts.unusedSessionReasonDetails.${d.reasonDetails}`) : '-'}
                  />
                  {d.reasonDetails === 'other' && (
                    <StepperHeaderValuePair
                      header={fm('tdcs.unusedSessionReasonDetailsOther')}
                      value={d.reasonDetailsOther && d.reasonDetailsOther.length > 0 ? d.reasonDetailsOther : '-'}
                    />
                  )}
                </React.Fragment>
              )}
            </React.Fragment>
          )}
          addNewTextHeader=""
          addNewTextButton="tdcs.newSession"
          buttonDisabled={eventType !== 'unusedSession'}
          hideNewButton={eventType !== 'unusedSession'}
          buttonDisabledMessage={fm('tdcs.chooseEventType')}
          previousEventsTextHeader="tdcs.previousUnusedSessions"
          editingElements={(index: number, onChange: IFormData['onChange']): JSX.Element => {
            const patientInducedReasonDetails = [
              'forgotten',
              'sick',
              'blockedByOtherReason',
              'confusionAboutSessionTime',
              'unknown',
              'other',
            ];
            const unitInducedReasonDetails = [
              'staffShortage',
              'deviceRelated',
              'cancelledDueToEmergencyPatient',
              'confusionInSessionBooking',
              'other',
            ];

            return (
              <React.Fragment>
                <StepperHeaderFormInputPair
                  header={fm('general.date')}
                  input={
                    <InputHandler
                      type="PartialDate"
                      editing={true}
                      name="date"
                      formData={{
                        onChange,
                        document: { date: formData.document.unusedSessions?.[index]?.date || '' },
                      }}
                      dateDefault={dateDefault}
                      isNotCancellable={true}
                      dateHook={{
                        dateHookFloor: formData.document.date,
                        dateHookCeiling: precedingDoctorsOrder(0, [])?.tdcs?.endTDCS
                          ? precedingDoctorsOrder(0, [])?.date
                          : undefined,
                      }}
                    />
                  }
                />
                <StepperHeaderFormInputPair
                  header={fm('tdcs.unusedSessionReason')}
                  input={
                    <InputHandler
                      type="Radio"
                      editing={true}
                      name="reason"
                      formData={{
                        onChange,
                        document: {
                          reason: formData.document.unusedSessions?.[index]?.reason || '',
                          reasonDetails: formData.document.unusedSessions?.[index]?.reasonDetails,
                          reasonDetailsOther: formData.document.unusedSessions?.[index]?.reasonDetailsOther,
                        },
                      }}
                      options={['patientInduced', 'unitInduced']}
                      optionFormatter={(id: string | number) => fm(`tdcs.opts.unusedSessionReason.${id}`)}
                      dependentFieldsRemovalWarning={true}
                      dependentFieldsList={
                        formData.document.unusedSessions?.[index]?.reasonDetails !== 'other'
                          ? () => ['reasonDetails', 'reasonDetailsOther']
                          : undefined
                      }
                    />
                  }
                />
                {formData.document.unusedSessions?.[index]?.reason && (
                  <React.Fragment>
                    <StepperHeaderFormInputPair
                      header={fm('tdcs.unusedSessionReasonDetails')}
                      input={
                        <InputHandler
                          type="Radio"
                          editing={true}
                          name="reasonDetails"
                          formData={{
                            onChange,
                            document: {
                              reasonDetails: formData.document.unusedSessions?.[index]?.reasonDetails || '',
                              reasonDetailsOther: formData.document.unusedSessions?.[index]?.reasonDetailsOther,
                            },
                          }}
                          options={
                            formData.document.unusedSessions?.[index]?.reason === 'patientInduced'
                              ? patientInducedReasonDetails
                              : unitInducedReasonDetails
                          }
                          optionFormatter={(id: string | number) => fm(`tdcs.opts.unusedSessionReasonDetails.${id}`)}
                          dependentFieldsRemovalWarning={true}
                          dependentFieldsList={() => ['reasonDetailsOther']}
                        />
                      }
                    />
                    {formData.document.unusedSessions?.[index]?.reasonDetails === 'other' && (
                      <StepperHeaderFormInputPair
                        header={fm('tdcs.unusedSessionReasonDetailsOther')}
                        input={
                          <InputHandler
                            type="TextArea"
                            editing={true}
                            name="reasonDetailsOther"
                            formData={{
                              onChange,
                              document: {
                                reasonDetailsOther: formData.document.unusedSessions?.[index]?.reasonDetailsOther || '',
                              },
                            }}
                            placeholder="tdcs.unusedSessionReasonDetailsOther"
                          />
                        }
                      />
                    )}
                  </React.Fragment>
                )}
              </React.Fragment>
            );
          }}
          theme={{ highlightColor: colors.highlight.light }}
        />
        <EventStepper
          name="otherEvents"
          formData={formData}
          mutators={{ creationMutator }}
          stepLabelText={(d: ITDCSOtherEvent): string =>
            `${formatPartialDate(d.date)} - ${fm(`tdcs.opts.eventTypes.${d.type}`)}`
          }
          stepContent={(d: ITDCSOtherEvent): JSX.Element => (
            <React.Fragment>
              <StepperHeaderValuePair
                header={fm('tdcs.typeOfEvent')}
                value={d.type ? fm(`tdcs.opts.eventTypes.${d.type}`) : '-'}
              />
              <React.Fragment>
                <StepperHeaderValuePair header={fm('tdcs.details')} value={d.details ?? '-'} />
              </React.Fragment>
            </React.Fragment>
          )}
          addNewTextHeader=""
          addNewTextButton="tdcs.newSession"
          buttonDisabled={!['treatmentMonitoring', 'doctorInterview', 'nurseReception'].includes(eventType ?? '')}
          buttonDisabledMessage={fm('tdcs.chooseEventType')}
          previousEventsTextHeader="tdcs.previousSessions"
          hideNewButton={!['treatmentMonitoring', 'doctorInterview', 'nurseReception'].includes(eventType ?? '')}
          editingElements={(index: number, onChange: IFormData['onChange']): JSX.Element => {
            if (!formData.document.otherEvents?.[index].type) {
              onChange?.({ type: eventType });
            }
            return (
              <React.Fragment>
                <StepperHeaderFormInputPair
                  header={fm('general.date')}
                  input={
                    <InputHandler
                      type="PartialDate"
                      editing={true}
                      name="date"
                      formData={{
                        onChange,
                        document: { date: formData.document.otherEvents?.[index]?.date || '' },
                      }}
                      dateDefault={dateDefault}
                      isNotCancellable={true}
                      dateHook={{
                        dateHookFloor: formData.document.date,
                        dateHookCeiling: precedingDoctorsOrder(0, [])?.tdcs?.endTDCS
                          ? precedingDoctorsOrder(0, [])?.date
                          : undefined,
                      }}
                    />
                  }
                />
                <StepperHeaderFormInputPair
                  header={fm('tdcs.details')}
                  input={
                    <InputHandler
                      type="TextArea"
                      editing={true}
                      name="details"
                      formData={{
                        onChange,
                        document: {
                          details: formData.document.otherEvents?.[index]?.details || '',
                        },
                      }}
                    />
                  }
                />
              </React.Fragment>
            );
          }}
        />
      </EventStepperGroup>
      {treatmentEfficiencyState && (
        <TasklistDialog
          taskActionType={
            treatmentEfficiencyState === 'treatmentEfficiencyNotStarted'
              ? 'tdcsTreatmentEfficiencyBefore'
              : 'tdcsTreatmentEfficiencyAfter'
          }
          sessionDate={nowPartialDate()}
          dialogOpen={taskListDialogOpen}
          setDialogOpen={setTaskListDialogOpen}
          fm={fm}
        />
      )}
    </React.Fragment>
  );
};

export default Sessions;
