import * as React from 'react';
import { MyServiceContext } from '../..';
import colors from '../../../../../config/theme/colors';
import BlockWrapper from 'Components/_NewElements/BlockWrapper';
import FormRow from 'Components/FormRow';
import InputHandler from 'Components/InputHandler';
import { isPartialDate, nowPartialDate, partialDateToValue, sortPartialDate } from 'neuro-utils';
import TableContent from './components/TableContent';
import { isLocaleKey, ITreatmentEfficiency, treatmentEfficiency, treatmentMonitoringInquiry } from 'neuro-schemas';
import { Container, Item } from 'Components/Grid';
import { styled } from '@mui/system';
import { TSurveyType } from 'Routes/MyService/util';
import { equals } from 'ramda';
import ConfirmationDialog from 'Components/ConfirmationDialog';

const StyledHeader = styled('div')({
  color: `${colors.primary}`,
  fontSize: '2rem',
  fontWeight: 600,
});

const TreatmentEfficiency = ({ documents }: IOwnProps): JSX.Element => {
  const treatmentEfficiencyLocales = treatmentEfficiency.localizations;
  const myServiceContext = React.useContext(MyServiceContext);
  const { editing, setEditingData, fm, locale, viewing } = myServiceContext;

  const [dateChangeDialog, setDateChangeDialog] = React.useState(false);
  const [dateChangeDialogData, setDateChangeDialogData] = React.useState<
    { prev: PartialDate | undefined; new: PartialDate | undefined } | undefined
  >(undefined);

  const treatmentPeriodsSorted = (
    documents.filter((d) => d._type === 'ninmtTreatmentPeriod') as Array<ININMTTreatmentPeriod & IControlProps>
  ).sort((a, b) => sortPartialDate(b.date, a.date));

  const symptomsAndLocationsDocs = documents.filter((d) => d._type === 'symptomsAndLocations') as Array<
    ISymptomsAndLocations & IControlProps
  >;

  const useLocale = isLocaleKey(locale) ? locale : 'fi';

  const fmTreatmentEfficiency = (id: string | number) => {
    return treatmentEfficiencyLocales[useLocale][id];
  };

  const [symptomsAndLocations, setSymptomsAndLocations] = React.useState<
    | Array<{
        symptom: 'other' | 'pain' | 'tinnitus';
        location: string;
      }>
    | undefined
  >(undefined);

  const treatmentMonitoringInquiryUtils = treatmentMonitoringInquiry.utils;

  const treatmentEfficiencyData = ((editing?.data || viewing?.data) ?? {}) as ITreatmentEfficiency & IControlProps;

  const handleDateChange = (value: PartialDate) => {
    const prevVal = treatmentEfficiencyData.date;
    const currentTreatmentPeriodId = treatmentPeriodsSorted.find(
      (tp) => partialDateToValue(tp.date) <= partialDateToValue(value),
    )?._id;

    const symptomsAndLocationsPairs = treatmentMonitoringInquiryUtils.getSymptomAndLocationPairs(
      symptomsAndLocationsDocs.filter(
        (d) =>
          d.treatmentPeriodId === currentTreatmentPeriodId &&
          d.mentalSymptom !== true &&
          partialDateToValue(d.startDate) <= partialDateToValue(value) &&
          (!d.endDate || partialDateToValue(value) <= partialDateToValue(d.endDate)),
      ),
      false,
    );

    if (
      !symptomsAndLocations ||
      (symptomsAndLocations?.every((p) => symptomsAndLocationsPairs.some((p2) => equals(p, p2))) &&
        symptomsAndLocationsPairs.every((p) => symptomsAndLocations?.some((p2) => equals(p, p2)))) ||
      (editing?.type === 'treatmentEfficiency' && (editing?.data as TSurveyType)?._commitsLength)
    ) {
      setEditingData?.({ ...treatmentEfficiencyData, date: value });
    } else {
      setDateChangeDialog(true);
      setDateChangeDialogData({ prev: prevVal, new: value });
    }
  };

  const onAcceptDateChange = () => {
    setEditingData?.({ ...treatmentEfficiencyData, date: dateChangeDialogData?.new });
    setDateChangeDialog(false);
  };

  const onCancelDateChange = () => {
    setEditingData?.({ ...treatmentEfficiencyData, date: dateChangeDialogData?.prev });
    setDateChangeDialog(false);
  };

  /**
   * @param {'pain' | 'tinnitus' | 'other'} key - The symptom to change
   * @param {number} index - Specify which index to change
   * @returns {void}
   */
  const handleSymptomFieldChange =
    (key: 'pain' | 'tinnitus' | 'other', index?: number) =>
    (e: TOnChangeValues): void => {
      const name = Object.keys(e)[0];
      const value = e[name];
      if (
        key &&
        (index || index === 0) &&
        Array.isArray(treatmentEfficiencyData[key]) &&
        (treatmentEfficiencyData[key as keyof typeof treatmentEfficiencyData] as [])[index]
      ) {
        const newValues = JSON.parse(JSON.stringify(treatmentEfficiencyData[key]));
        newValues[index] = { ...newValues[index], [name]: value };
        setEditingData?.({
          ...treatmentEfficiencyData,
          [key]: newValues,
        });
      } else setEditingData?.({ ...treatmentEfficiencyData, [name]: value });
    };

  const getFieldValue = (data: typeof treatmentEfficiencyData, name: string) => {
    const value = data[name as keyof typeof treatmentEfficiencyData];
    return value;
  };

  // Set date if editing and no date found
  React.useEffect(() => {
    if (!treatmentEfficiencyData.date && isEditing) {
      setEditingData?.({ ...treatmentEfficiencyData, date: nowPartialDate() });
    }
  }, []);

  React.useEffect(() => {
    if (viewing) return;
    if (!treatmentEfficiencyData.date) return;
    if (editing?.type === 'treatmentEfficiency' && (editing?.data as TSurveyType)?._commitsLength) return;

    const currentTreatmentPeriodId = treatmentPeriodsSorted.find(
      (tp) => partialDateToValue(tp.date) <= partialDateToValue(treatmentEfficiencyData.date),
    )?._id;

    const symptomsAndLocationsPairs = treatmentMonitoringInquiryUtils.getSymptomAndLocationPairs(
      symptomsAndLocationsDocs.filter(
        (d) =>
          d.treatmentPeriodId === currentTreatmentPeriodId &&
          d.mentalSymptom !== true &&
          partialDateToValue(d.startDate) <= partialDateToValue(treatmentEfficiencyData.date) &&
          (!d.endDate ||
            (treatmentEfficiencyData.date &&
              isPartialDate(treatmentEfficiencyData.date) &&
              partialDateToValue(treatmentEfficiencyData.date) <= partialDateToValue(d.endDate))),
      ),
      false,
    );

    if (!equals(symptomsAndLocationsPairs, symptomsAndLocations)) {
      setSymptomsAndLocations(symptomsAndLocationsPairs);
    }
  }, [treatmentEfficiencyData.date]);

  // Initialize document data based on symptomsAndLocationsPairs (when creating a new survey doc or date is changed)
  React.useEffect(() => {
    if (!symptomsAndLocations) return;

    if (symptomsAndLocations.length > 0) {
      const ratingValues: { [key: string]: unknown } = {
        intensity: null,
        harm: null,
      };

      const newPain: ITreatmentEfficiency['pain'] = [];
      const newTinnitus: ITreatmentEfficiency['tinnitus'] = [];
      const newOther: ITreatmentEfficiency['other'] = [];

      symptomsAndLocations.forEach((sl) => {
        switch (sl.symptom) {
          case 'pain':
            newPain.push({ location: sl.location as ISymptomsAndLocations['symptomLocation'], ...ratingValues });
            break;
          case 'tinnitus':
            newTinnitus.push({ location: sl.location as ISymptomsAndLocations['symptomLocation'], ...ratingValues });
            break;
          case 'other': {
            newOther.push({ description: sl.location, ...ratingValues });
          }
        }
      });

      const newData: typeof treatmentEfficiencyData = { ...treatmentEfficiencyData };

      if (newPain.length > 0) newData.pain = newPain;
      if (newTinnitus.length > 0) newData.tinnitus = newTinnitus;
      if (newOther.length > 0) newData.other = newOther;

      setEditingData?.(newData);
    }
  }, [symptomsAndLocations]);

  const isEditing = !viewing && !!editing;

  // TODO: Should the survey contain information of the treatment it is related to (RTMS/TDCS)

  return (
    <React.Fragment>
      <FormRow title="general.date" headerWidth={!editing ? 1 : 2.77}>
        <InputHandler
          name="date"
          type="PartialDate"
          editing={!!editing}
          isNotCancellable={true}
          formData={{
            document: { date: treatmentEfficiencyData?.date },
            onChange: (e: TOnChangeValues) => {
              const value = e.date;
              if (value && isPartialDate(value)) {
                handleDateChange(value);
              }
            },
          }}
          dateDefault="now"
        />
      </FormRow>
      <FormRow title="myService.ninmt.timing" headerWidth={!editing ? 1 : 2.77}>
        <InputHandler
          name="timing"
          type="Radio"
          editing={!!editing}
          formData={{
            document: { timing: getFieldValue(treatmentEfficiencyData, 'timing') },
            onChange: (values: TOnChangeValues) => {
              setEditingData?.({ ...treatmentEfficiencyData, ...values });
            },
          }}
          options={['before', 'after']}
          optionFormatter={(id: string | number) => fmTreatmentEfficiency(id)}
        />
      </FormRow>
      <BlockWrapper>
        <Container style={{ justifyContent: 'space-between', alignItems: 'center', marginBottom: '2.5rem' }}>
          <Item xs={10}>
            <StyledHeader>{fmTreatmentEfficiency('patientEstimate')}</StyledHeader>
          </Item>
          <Item xs={2} />
        </Container>
        {(['pain', 'tinnitus', 'other'] as ('pain' | 'tinnitus' | 'other')[])
          .filter((key) => treatmentEfficiencyData && Object.keys(treatmentEfficiencyData).includes(key))
          .map((key, ind) => {
            const symptomArray = treatmentEfficiencyData?.[key as keyof typeof treatmentEfficiencyData];
            if (!Array.isArray(symptomArray) || symptomArray.length === 0) return;

            // The table can fit 5 locations/descriptions
            const width = {
              1: 38.5,
              2: 53.8,
              3: 69.1,
              4: 84.5,
              5: 100,
            }[symptomArray.length];

            return (
              <div key={key + ind} style={{ marginBottom: '2.5rem' }}>
                <div style={{ fontSize: '1.8.rem', fontWeight: 600, color: colors.darkGray }}>
                  {fm(`diagnosis.ninmt.symptomsAndLocations.opts.${key}`)}
                </div>
                <TableContent
                  width={width}
                  titleRow={{
                    title: { content: '' },
                    content: symptomArray.map((s) => {
                      let content = '-';
                      if (!s || typeof s !== 'object') return { content };

                      if ('location' in s && s.location) {
                        content = fm(`diagnosis.ninmt.symptomsAndLocations.opts.${s.location}`);
                      } else if ('description' in s && s.description) {
                        content = s.description;
                      }

                      return { content };
                    }),
                  }}
                  bottomMargin={false}
                >
                  {[
                    ...['intensity', 'harm'].map((field) => ({
                      title: {
                        content: fmTreatmentEfficiency(field),
                      },
                      content: symptomArray.map((_, i) => ({
                        content: (
                          tabIndex: TTabulationTools['tabIndex'],
                          inputRefs: TTabulationTools['inputRefs'],
                          id: TTabulationTools['identifier'],
                        ) => (
                          <InputHandler
                            type="NumberField"
                            name={field}
                            editing={isEditing}
                            formData={{
                              document: {
                                [field]: getFieldValue((symptomArray as [])[i], field),
                              },
                              onChange: (e) => handleSymptomFieldChange(key, i)(e),
                            }}
                            placeholder="0-10"
                            disablePlaceholderFormatting
                            width={18}
                            min={0}
                            max={10}
                            precision={1}
                            enableTrailingZero
                            tabulationTools={{
                              tabIndex,
                              inputRefs,
                              identifier: id,
                            }}
                          />
                        ),
                        style: { textOverflow: 'clip' },
                      })),
                    })),
                    {
                      title: {
                        content: `0 = ${fm('myService.ninmt.treatmentMonitoringInquiry.fieldMinInfo')} 10 = ${fm(
                          'myService.ninmt.treatmentMonitoringInquiry.fieldMaxInfo',
                        )} (NRS)`,
                        isInfo: true,
                      },
                      content: [
                        {
                          content: '',
                        },
                      ],
                    },
                  ]}
                </TableContent>
              </div>
            );
          })}
      </BlockWrapper>
      <ConfirmationDialog
        open={dateChangeDialog}
        text={fm('general.dependentFieldsRemovalWarning')}
        confirm={{ callback: onAcceptDateChange, text: 'general.continue' }}
        cancel={{ callback: onCancelDateChange }}
      />
    </React.Fragment>
  );
};

interface IOwnProps {
  documents: Array<
    (IDoctorsOrder | IRTMS | ITDCS | ITreatmentMonitoringInquiry | ITreatmentEfficiency | ISymptomsAndLocations) &
      IControlProps
  >;
}

export default TreatmentEfficiency;
